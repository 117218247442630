import React, {useCallback, useContext} from 'react';
import {makeStyles} from "@mui/styles";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {deleteOrganizationFaq} from "../../../lib/api";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.primary.backgroundColor,
  },
  progressBarColor:{
    backgroundColor: theme.palette.primary.backgroundColor,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
}));


const DeleteFAQDialog = ({ handleClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    state: {  selectedFAQ, token, userID },
    actions: { refetchFaq },
  } = useContext(DemoContext);
  
  const handleDelete = useCallback(() => {
      deleteOrganizationFaq(token, userID, selectedFAQ.id).then(()=>{
        refetchFaq();
        handleClose();
        navigate(`../dashboard/signatures/`)
      })
  },[selectedFAQ, refetchFaq]);
  
  return (
    <>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={true}
        onClose={handleClose}
        aria-labelledby="share-dialog-title" className={classes.form}
      >
        <DialogTitle id="share-dialog-title"><Delete/> Confirm Deletion?</DialogTitle>
        <DialogContent>
            <>
                <DialogContentText>
                  {selectedFAQ?.question}
                </DialogContentText>
              </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}
                  className={classes.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleDelete}
                  className={classes.addButton}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default DeleteFAQDialog;
