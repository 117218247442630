import React, {useCallback, useContext, useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip, InputAdornment, CircularProgress, IconButton, LinearProgress
} from "@mui/material";
import {Code, FileCopy, Link} from "@mui/icons-material";
import {DemoContext} from "../../../lib/contexts/DemoContext";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
}));

const EmbedCodeDialog = () => {
  const classes = useStyles();
  const {
    actions: {  enqueueSnackbar },
    state: { embedCode, matchSM, matchMD, isLoadingCurrentUser, isRefetchingCurrentUser },
  } = useContext(DemoContext);
  const [showingDialog, setShowingDialog] = useState(false);
  const [isAdShown, setIsAdShown] = useState(false);
  
  useEffect(() => {
    if (showingDialog) {
      setTimeout(() => {
        setIsAdShown(true);
      }, 10000);
    } else {
      setIsAdShown(false);
    }
  }, [showingDialog]);
  
  const handleClose = useCallback(()=>{
    setShowingDialog(false)
  },[])
  const handleOpen = useCallback(async () =>{
    setShowingDialog(true)
  },[])
  const handleCopy = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard()
       .then(() => {
         enqueueSnackbar('Copied embed code.', {variant:'info'})
       })
       .catch((err) => {
         enqueueSnackbar('Your browser settings have prevented automatic copying. Please copy manually.', {variant:'error'})
         //console.log(err);
       });
  }
  const copyTextToClipboard = useCallback(async () => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(embedCode);
    } else {
      return document.execCommand('copy', true, embedCode);
    }
  },[embedCode]);
  const InternalTooltip = React.forwardRef((props, ref) => (
     <Tooltip {...props} ref={ref} />
  ));
  return (
     <>
       <Button style={{display:'block',margin:'10px auto auto'}} fullWidth={matchSM || matchMD} variant={'outlined'} color={'primary'} onClick={handleOpen}>
         <Tooltip title={'Generate Embed Code'}>
           { isLoadingCurrentUser || isRefetchingCurrentUser ? <CircularProgress size={20} /> : <><Code/>&nbsp;Generate Embed Code</> }
         </Tooltip>
       </Button>
       <Dialog
          maxWidth={'md'}
          fullWidth={true}
          open={showingDialog}
          onClose={handleClose}
          aria-labelledby="scan-url-dialog-title" className={classes.form}
       >
         <DialogTitle id="scan-url-dialog-title"><Code style={{marginTop:-7}}/> Embed Code</DialogTitle>
         <DialogContent>
           { !isAdShown ?  <>
             <DialogContentText>
               We're generating your custom Embed Code.<br/>
               In the meantime, please consider checking out this remarkable product:
               <a href={'https://supportgpt.co?referrer=https://EmailSignatures.app'} target={'_blank'} style={{margin:'auto', textAlign:'center', display:'block'}}>
                 <img src={'https://supportgpt.co/social.png'} style={{margin:20, height: 300}} />
               </a>
               <LinearProgress style={{margin:20}} />
             </DialogContentText>
             </>
              :
             <>
             <DialogContentText>
               To complete installation, Copy and Paste the following snippet directly into your Email Client's
               settings.
             </DialogContentText>
    
             <TextField
                rows={11}
                multiline={true}
                key='embed_script'
                id='embed_script'
                label=''
                value={embedCode}
                margin='normal'
                variant='outlined'
                fullWidth={true}
                type={'text'}
                className={classes.embedCode}
       
                InputProps={{
                  endAdornment: (
                     <InputAdornment position="end">
                       <InternalTooltip title={'Copy Embed Code'}>
                         <IconButton aria-label="Copy Embed Code" onClick={handleCopy} size="large">
                           <FileCopy/>
                         </IconButton>
                       </InternalTooltip>
                     </InputAdornment>
                  ),
                }}
             />
           </>}
         </DialogContent>
         <DialogActions>
           <Button onClick={handleClose}
                   className={classes.cancelButton}>
             Close
           </Button>
           <Button onClick={handleCopy}
                   className={classes.addButton} disabled={!isAdShown}>
             Copy
           </Button>
         </DialogActions>
       </Dialog>
     </>
  );
}
export default EmbedCodeDialog;
