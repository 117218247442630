import * as React from 'react';
import {
  Box, Button, ButtonGroup,
  Chip, ClickAwayListener, DialogActions, Divider, FormControl, Grid, Grow,
  IconButton, InputAdornment, InputLabel,
  ListItem,
  ListItemIcon, ListItemSecondaryAction,
  ListItemText, Menu, MenuItem, MenuList,
  Paper, Popper, Select, Switch, TextField, Tooltip,
  Typography,
} from '@mui/material';
//import {ColorPicker} from "material-ui-color";
import { ColorPicker } from '@wellbees/color-picker-input'

import {
  Add, AlternateEmail,
  ArrowDropDown, Business,
  Close, Email, Face, Facebook,
  FilterList,
  Help, HowToReg, Http, Instagram, LinkedIn,
  Notifications,
  NotificationsOff,
  Save,
  Star, Twitter, Visibility, VisibilityOff, YouTube
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import useOrganizationFaqs from '../../lib/hooks/useOrganizationFaqs';
import {useCallback, useContext, useMemo, useState} from 'react';
import {toCapitalizeFromUnderScore, toUnderScoreSlug} from '../../lib/helpers/helpers';
import { useParams } from 'react-router';
import {getButtonBorderColor} from "../../lib/helpers/colorHelpers";
import {GlossaryContext} from "../../lib/contexts/GlossaryContext";
import {updateOrganization} from "../../lib/api";
import {DemoContext} from "../../lib/contexts/DemoContext";
import all_countries from "../../lib/constants/countries";
import {confidentialityClauses} from "../../lib/constants/constants";
import {TEMPLATES} from "../../lib/constants/templates";

const useStyles = makeStyles(theme => ({
  root: {
    display:'block',
    width: '100%',
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
  },
  containerTop: {
    //maxHeight: '25vh',
    overflowY: 'auto',
    marginLeft:0,
    marginRight:0,
    '& *':{
      overflow: 'visible',
    }
  },
  container: {
   // maxHeight: '40vh',
    overflowY: 'auto',
  },
  chip: {
    margin: 8,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: theme.palette.primary.paperText,
  },
  button: {},
  countrySelect: {
    '&.MuiInput-underline:before': {
      borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
    },
    '&.MuiInput-underline:after': {
      borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
    },
  },
}));

export default function SidebarManagement() {
  const classes = useStyles();
  const previewOptions = ['Closed', 'Open', 'Conversation']
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  
  const {
    state: {
      isLoadingCurrentUser,
      isLoadingOrganization,
      matchSM,
      userID,
      poweredBy,
      confidentialNotice,
      primaryColor,
      secondaryColor,
      tertiaryColor,
      quaternaryColor,
      companyAIName,
      companyName,
      companyDesc,
       chatPosition,
      companyStatus,
       companyWebsite,
      companyPhone,
      companyCountryPhone,
      companyEmail,
      companyEmailNotifications,
      showTeam,
      showFAQ,
       buttonGradient,
      collectEmail,
      collectPhone,
      autoPrompt,
      autoSlack,
      companyWebhook,
      companyAIPersonality,
      signatureTemplate,
       previewMode,
      currentPlan,
  
      fieldsConfig,
      values
    },
    actions: {
      refetchCurrentUser,
      handleNotificationSave,
      isFeatureAllowed,
      setPoweredBy,
      setPrimaryColor,
      setSecondaryColor,
      setTertiaryColor,
      setQuaternaryColor,
       setButtonGradient,
      setCompanyAIName,
      setCompanyName,
      setCompanyDesc,
      setCompanyWebsite,
      setCompanyPhone,
      setCompanyCountryPhone,
      setCompanyEmail,
      setCompanyAIPersonality,
      setSignatureTemplate,
      setCompanyType,
      setChatPosition,
       setCompanyStatus,
       setCompanyWebhook,
       setShowTeam,
      setShowFAQ,
      setCollectEmail,
      setCollectPhone,
      setAutoPrompt,
       setAutoSlack,
      handleSave,
       setPreviewMode,
      enqueueSnackbar,
  
      setValues,
      toggleVisibility,
      setConfidentialNotice
       },
  } = useContext(DemoContext);
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleMenuItemClick = useCallback((event, index) => {
    setSelectedIndex(index)
    setPreviewMode(previewOptions[index])
    handleToggle()
  },[]);
  
  
  const defaultClause = confidentialityClauses.filter(clause => clause.default)[0].text;
  
  return (
    <Paper className={classes.root}>
      <Grid
         container
         item xs={12}
         direction="row"
         justifyContent="space-evenly"
         alignItems="flex-start"
         spacing={2}
         className={classes.container}
      >
        <Grid item xs={12}>
          <>
            <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 20 }}>
              Customize
            </Typography>
            <Box component="nav" aria-label="organization information" className={classes.containerTop}>
              
              <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" title={'Personalize the overall layout of the signature. By default, it\'s set to Horizontal.'}
                                 size={'small'} style={{width:'100%', marginTop:16}}>
                      <InputLabel id="company-theme-label">Layouts</InputLabel>
                      <Select
                         fullWidth={true}
                         labelId="company-theme-label"
                         id="company-theme-outlined"
                         value={signatureTemplate}
                         onChange={(e) => {
                           setSignatureTemplate(e.target.value)
                           localStorage.setItem('signature_template', e.target.value)
                         }}
                         label="Layouts"
                      >
                        {TEMPLATES.map((item) => (
                           <MenuItem key={item}  value={item.name} title={toCapitalizeFromUnderScore(item.name)}>
                             {toCapitalizeFromUnderScore(item.name)}
                           </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
  
                    <FormControl variant="outlined" title={'Personalize the overall layout of the signature. By default, it\'s set to Horizontal.'}
                                 size={'small'} style={{width:'100%', marginTop:16}}>
                      <InputLabel id="company-theme-label">Clauses</InputLabel>
                      <Select
                         fullWidth={true}
                         labelId="company-theme-label"
                         id="company-theme-outlined"
                         value={confidentialNotice}
                         onChange={(e) => {
                           setConfidentialNotice(e.target.value)
                           localStorage.setItem('signature_notice', e.target.value)
                         }}
                         label="Clauses"
                      >
                        <MenuItem value={null}>None</MenuItem>
                        {confidentialityClauses.map((item) => (
                           <MenuItem key={item}  value={item.text} title={toCapitalizeFromUnderScore(item.label)}>
                             {toCapitalizeFromUnderScore(item.label)}
                           </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                       disabled={isLoadingCurrentUser}
                       size={'small'}
                       fullWidth={true}
                       inputType="input"
                       pickerType='twitter'
                       colorShowType={'circle'}
                       id={'primaryColor'} value={primaryColor} onChange={value=>{
                        setPrimaryColor(value)
                        localStorage.setItem('signature_primary_color', value)
                    }} />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                       disabled={isLoadingCurrentUser}
                       size={'small'}
                       fullWidth={true}
                       inputType="input"
                       pickerType='twitter'
                       colorShowType={'circle'}
                       id={'secondaryColor'} value={secondaryColor} onChange={value=>{
                        setSecondaryColor(value)
                        localStorage.setItem('signature_secondary_color', value)
                    }} />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                       disabled={isLoadingCurrentUser}
                       size={'small'}
                       fullWidth={true}
                       inputType="input"
                       pickerType='twitter'
                       colorShowType={'circle'}
                       id={'tertiaryColor'} value={tertiaryColor} onChange={value=>{
                        setTertiaryColor(value)
                        localStorage.setItem('signature_tertiary_color', value)
                    }} />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                       disabled={isLoadingCurrentUser}
                       size={'small'}
                       fullWidth={true}
                       inputType="input"
                       pickerType='twitter'
                       colorShowType={'circle'}
                       id={'quaternaryColor'} value={quaternaryColor} onChange={value=>{
                        setQuaternaryColor(value)
                        localStorage.setItem('signature_quaternary_color', value)
                    }} />
                  </Grid>
                
                {fieldsConfig.map((config) => (
                   <Grid item xs={6} key={config.key}>
                     <FormField
                        toggleVisibility={toggleVisibility}
                        config={config}
                        value={values[config.key]}
                        setValue={(value) => setValues({...values, [config.key]: value})}
                     />
                   </Grid>
                ))}
  
                <Grid item xs={6} style={{display:'none'}}>
                <List>
                  <Tooltip title={'Show support!'}>
                    <ListItem dense>
                      <ListItemText>Show &quot;Powered By&quot;</ListItemText>
                      <ListItemSecondaryAction>
                        <Switch
                           disabled={!isFeatureAllowed('show_powered_by')}
                           size={'medium'} color={'primary'} checked={poweredBy} onChange={()=>setPoweredBy(!poweredBy)}/>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Tooltip>
                </List>
                </Grid>
                
              </Grid>
              
              <Grid style={{display:"none"}} container spacing={0}>
                <Grid item xs={12}>
                  <Tooltip title={"This is the public display name."}>
                  <TextField
                     size={'small'}
                     key='ai_name'
                     id='ai_name'
                     defaultValue={'Full Name'}
                     placeholder={'Steve Jobs'}
                     label='Full Name'
                     value={companyAIName}
                     margin='normal'
                     variant='outlined'
                     fullWidth={true}
                     type={'text'}
                     onChange={()=>setCompanyAIName(event.target.value.replace(/[^a-zA-Z]/g, '').substring(0,10))}

                  />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"This is your professional title."}>
                  <TextField
                     size={'small'}
                     key='ai_name'
                     id='ai_name'
                     defaultValue={'EmailSignature.app'}
                     placeholder={'EmailSignature.app'}
                     label='Title'
                     value={companyAIName}
                     margin='normal'
                     variant='outlined'
                     fullWidth={true}
                     type={'text'}
                     onChange={()=>setCompanyAIName(event.target.value.replace(/[^a-zA-Z]/g, '').substring(0,10))}

                  />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"This is the name of the company the AI will represent."}>
                  <TextField
                     autoComplete={'no'}
                     size={'small'}
                     key='company_name'
                     id='company_name'
                     label='Company Name'
                     placeholder={'Acme Corp'}
                     value={companyName}
                     margin='normal'
                     variant='outlined'
                     fullWidth={true}
                     type={'text'}
                     onChange={()=>setCompanyName(event.target.value)}
                  />
                  </Tooltip>
                </Grid>
  
                <Grid item xs={12}>
                  <Tooltip title={"Provide the domain where the AI will be installed."}>
                    <TextField
                       required={true}
                       autoComplete={'no'}
                       size={'small'}
                       key='company_site'
                       id='company_site'
                       label='Website'
                       placeholder={'Example.com'}
                       value={companyWebsite}
                       margin='normal'
                       variant='outlined'
                       fullWidth={true}
                       type={'text'}
                       onChange={()=>setCompanyWebsite(event.target.value)}
                       InputProps={{
                         startAdornment: <InputAdornment position="start">
                           https://
                         </InputAdornment>,
                       }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"Provide a phone number to promote."}>
                    <TextField
                       autoComplete={'no'}
                       size={'small'}
                       key='company_phone'
                       id='company_phone'
                       label='Phone Number'
                       value={companyPhone}
                       margin='normal'
                       variant='outlined'
                       fullWidth={true}
                       type={'text'}
                       onChange={()=>setCompanyPhone(event.target.value.replace(/[^0-9]/g, '').substring(0,10))}
                       InputProps={{
                         maxLength: 10,
                         mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                         startAdornment: <InputAdornment position="start" style={{width:160}}>
                           <Select
                              aria-label="select from country phone codes"
                              value={companyCountryPhone}
                              variant={"standard"}
                              className={classes.countrySelect}
                           >
                             {all_countries.map((item)=> <MenuItem key={item.code} value={item.phone}
                                                                   onClick={(e)=>setCompanyCountryPhone(item.phone)}>
                                  <img style={{height:30,width:30}} src={item.flag}/>&nbsp;&nbsp;{item.phone}
                                </MenuItem>
                             )}
                           </Select>
                         </InputAdornment>,
                       }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"Provide an email address to promote."}>
                    <TextField
                       autoComplete={'no'}
                       size={'small'}
                       key='company_email'
                       id='company_email'
                       label='Email'
                       placeholder={'notifications@example.com'}
                       value={companyEmail}
                       margin='normal'
                       variant='outlined'
                       fullWidth={true}
                       type={'text'}
                       onChange={()=>setCompanyEmail(event.target.value)}
                       InputProps={{
                         endAdornment: <InputAdornment position="end">
                           <Tooltip title={"Toggle your email notifications."}>
                           <Switch
                             onChange={()=>isFeatureAllowed('email_notifications') ? handleNotificationSave() : enqueueSnackbar('Please upgrade to an eligible plan.', {variant:'error'})}
                             size="large">
                             {companyEmailNotifications ? <Visibility/> : <VisibilityOff/>}
                           </Switch>
                           </Tooltip>
                         </InputAdornment>,
                       }}
                    />
                  </Tooltip>
                </Grid>
                </Grid>
            </Box>
          </>
        </Grid>
      </Grid>
      <DialogActions>
        <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button" style={{display:'none'}}>
          <Button onClick={handleToggle}>Preview</Button>
          <Button
             color="primary"
             size="small"
             aria-controls={open ? 'split-button-menu' : undefined}
             aria-expanded={open ? 'true' : undefined}
             aria-label="select preview mode"
             aria-haspopup="menu"
             onClick={handleToggle}
          >
            <ArrowDropDown />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
             <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
             >
               <Paper>
                 <ClickAwayListener onClickAway={handleToggle}>
                   <MenuList id="split-button-menu">
                     {previewOptions.map((option, index) => (
                        <MenuItem
                           key={option}
                           disabled={index === selectedIndex}
                           selected={index === selectedIndex}
                           onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                     ))}
                   </MenuList>
                 </ClickAwayListener>
               </Paper>
             </Grow>
          )}
        </Popper>
  
  
  
        <List>
          <Tooltip title={'Show support!'}>
            <ListItem dense>
              <ListItemText style={{marginRight:20}}>Show &quot;Powered By&quot;</ListItemText>
              <ListItemSecondaryAction>
                <Switch
                   disabled={!userID}
                   size={'medium'} color={'primary'} checked={poweredBy} onChange={()=>setPoweredBy(!poweredBy)}/>
              </ListItemSecondaryAction>
            </ListItem>
          </Tooltip>
        </List>
        <Button size={'medium'} variant={'outlined'} color={'primary'} onClick={handleSave}><Save/>&nbsp;Save</Button>
      </DialogActions>
    </Paper>
  );
}

export const FormField = ({config, value, setValue, toggleVisibility}) => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState('+1')
  
//  if (!config.visible) return null;
  
  let adornment;
  switch(config.startAdornment) {
    case 'url':
      adornment = <InputAdornment position="start">https://</InputAdornment>;
      break;
    case 'phone':
      adornment = <InputAdornment position="start" style={{width:160}}>
        <Select
           aria-label="select from country phone codes"
           value={countryCode}
           variant={"standard"}
           className={classes.countrySelect}
        >
          {all_countries.map((item)=>
             <MenuItem
                key={item.code}
                value={item.phone}
                onClick={(e)=>setCountryCode(item.phone)}
             >
               <img style={{height:30,width:30}} src={item.flag}/>&nbsp;&nbsp;{item.phone}
             </MenuItem>
          )}
        </Select>
      </InputAdornment>;
      break;
    case 'icon':
      adornment = <InputAdornment position="start">{config.icon}</InputAdornment>;
      break;
    default:
      adornment = null;
  }
  
  return (
     <Tooltip title={config.tooltip}>
       <TextField
          size={'small'}
          key={config.key}
          id={config.key}
          defaultValue={config.defaultValue}
          placeholder={config.placeholder}
          label={config.label}
          value={value}
          margin='normal'
          variant='outlined'
          fullWidth={true}
          type={config.type}
          onChange={(e) => setValue(e.target.value)} // You'll need to modify this depending on your use case
          InputProps={{
            startAdornment: adornment,
            endAdornment:
               <InputAdornment position="end">
                 <IconButton
                    edge="end"
                    onClick={() => toggleVisibility(config.key)}
                 >
                   {config.visible ? <Visibility /> : <VisibilityOff />}
                 </IconButton>
                 
               </InputAdornment>
          }}
       />
     </Tooltip>
  );
};
