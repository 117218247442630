import React, {useContext} from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import EmbeddableChatManagement from "../../../embeds/EmbeddableChat/EmbeddableChatManagement";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import Footer3 from "../../Shared/Footer3";

const Customize = () => {
   const {
      state: { matchSM },
   } = useContext(DemoContext);
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Customize' empty />
           <section className={`${matchSM ? '' :'sc-feature-section-area5'} sc-pt-30 sc-md-pt-30 sc-pb-140 sc-md-pb-70`}>
           <div className="container">
                 <div className="row align-items-center">
                     <EmbeddableChatManagement/>
                 </div>
              </div>
           </section>
           <Footer3/>
        </>
    );
};

export default Customize;
