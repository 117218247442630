import React, {useContext} from "react";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {TEMPLATES} from "../../../lib/constants/templates";
import {Button, CircularProgress, Divider, Tooltip} from "@mui/material";
import {renderToString} from "react-dom/server";
import {Link} from "@mui/icons-material";
import {EmbedCodeDialog} from "../EmbedCodeDialog";
import {hexToHsl, prettyPhone} from "../../../lib/helpers/helpers";

export const Preview = () => {
   const {
      state: {
         signatureTemplate: layout,
         fieldsConfig,
         values,
         isLoadingCurrentUser,
         isRefetchingCurrentUser,
         matchSM,
         matchMD,
         userID
      },
      actions: {
         setEmbedCode
      },
   } = useContext(DemoContext);
   const renderField = (field) => {
      const fieldValue = values[field.key] || '';
      return (
         <div key={field.key}>
            <strong>{field.label}:</strong> {fieldValue}
         </div>
      );
   };
   const fieldsToRender = fieldsConfig.filter(field => field.visible);
   const renderLayout = () => {
      const template = TEMPLATES.find(t => t.name === layout);
      switch(layout) {
         case 'Vertical':
            const verticalLayoutComponent = renderVerticalLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(verticalLayoutComponent));
            return verticalLayoutComponent;
         case 'Horizontal':
            const horizontalLayoutComponent = renderHorizontalLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(horizontalLayoutComponent));
            return horizontalLayoutComponent;
         case 'Circle':
            const circularLayoutComponent = renderCircularLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(circularLayoutComponent));
            return circularLayoutComponent;
         case 'Sidebar':
            const sidebarLayoutComponent = renderSidebarLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(sidebarLayoutComponent));
            return sidebarLayoutComponent;
         case 'Dual Sidebar':
            const dualSidebarLayoutComponent = renderDualSidebarTemplate(template.template, fieldsToRender);
            setEmbedCode(renderToString(dualSidebarLayoutComponent));
            return dualSidebarLayoutComponent;
         case 'Stacked':
            const stackedLayoutComponent = renderStackedLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(stackedLayoutComponent));
            return stackedLayoutComponent;
         case 'Block':
            const blockLayoutComponent = renderBlockLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(blockLayoutComponent));
            return blockLayoutComponent;
         case 'Boxed':
            const borderedLayoutComponent = renderBoxedLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(borderedLayoutComponent));
            return borderedLayoutComponent;
         case 'With Footer':
            const withFooterLayoutComponent = renderWithFooterLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(withFooterLayoutComponent));
            return withFooterLayoutComponent;
         case 'Logo at Top':
            const logoTopLayoutComponent = renderLogoAtTopLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(logoTopLayoutComponent));
            return logoTopLayoutComponent;
         case 'Logo at Bottom':
            const logoBottomLayoutComponent = renderLogoAtBottomLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(logoBottomLayoutComponent));
            return logoBottomLayoutComponent;
         case 'Two Columns':
            const twoColumnsLayoutComponent = renderTwoColumnsLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(twoColumnsLayoutComponent));
            return twoColumnsLayoutComponent;
         case 'Three Columns':
            const threeColumnsLayoutComponent = renderThreeColumnsLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(threeColumnsLayoutComponent));
            return threeColumnsLayoutComponent;
         case 'Centered':
            const centerLayoutComponent = renderCenteredLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(centerLayoutComponent));
            return centerLayoutComponent;
         case 'Minimalist':
            const minimalLayoutComponent = renderMinimalistLayout(template.template, fieldsToRender);
            setEmbedCode(renderToString(minimalLayoutComponent));
            return minimalLayoutComponent;
         case 'Card View':
            const cardViewLayoutComponent = renderCardViewTemplate(template.template, fieldsToRender);
            setEmbedCode(renderToString(cardViewLayoutComponent));
            return cardViewLayoutComponent;
         case 'Zigzag':
            const zigzagLayoutComponent = renderZigzagTemplate(template.template, fieldsToRender);
            setEmbedCode(renderToString(zigzagLayoutComponent));
            return zigzagLayoutComponent;
         // other layout cases go here
         default:
            return fieldsToRender ? fieldsToRender.map(renderField) : 'Loading...';
      }
   };
   return (
      <div>
         <Divider/>
         <br/>
         {renderLayout()}
         {!userID && <>
            <br/>
            <Divider/>
            <EmbedCodeDialog />
         </>}
      </div>
   );
};

const renderVerticalLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (<>
      Best Regards,
      <div style={{ marginTop:20, fontSize: '14px',
         padding: '20px 0',
         backgroundColor: '#f7f7f7',
         fontFamily: "'Helvetica Neue', Arial, sans-serif",
         borderBottom: '1px solid #ddd',
         color: '#333',
         ...primaryStyle,
          }}>
         <div style={{ marginBottom: '10px' }}>
            <img src={avatar || findFieldByKey('avatar_url')} alt={findFieldByKey('full_name')}
                 style={{ borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    objectFit: 'cover',
                    marginRight: '10px' }}
            />
            {findFieldByKey('full_name') && <strong>{findFieldByKey('full_name')}</strong>}
            {findFieldByKey('full_name') && (findFieldByKey('job_title') || findFieldByKey('company_name')) && <span style={secondaryStyle}> |</span>}
            {findFieldByKey('job_title') && <span style={secondaryStyle}>&nbsp;{findFieldByKey('job_title')}</span>}
            {findFieldByKey('job_title') && findFieldByKey('company_name') && <span style={secondaryStyle}> at</span>}
            {findFieldByKey('company_name') && <span style={secondaryStyle}>&nbsp;{findFieldByKey('company_name')}</span>}
         </div>
   
         <img src={companyLogo || findFieldByKey('logo_url')} alt={findFieldByKey('company_name') + " Logo"} style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px' }}/>
   
         {findFieldByKey('website') &&
            <p style={{ marginTop: '10px', ...tertiaryStyle }}>
               <a target={'_blank'} href={`https://${findFieldByKey('website')}`} style={{color: '#999', ...tertiaryStyle }}>{findFieldByKey('website')}</a>
            </p>
         }
         {findFieldByKey('phone_number') &&
            <p style={{ marginTop: '10px', ...tertiaryStyle }}>
               <a href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`} style={{color: '#999', ...tertiaryStyle }}>{prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}</a><br/>
            </p>
         }
         {findFieldByKey('email') &&
            <p style={{ marginTop: '10px', ...tertiaryStyle }}>
               <a href={`mailto:${findFieldByKey('email')}`} style={{color: '#999', ...tertiaryStyle }}>{findFieldByKey('email')}</a><br/>
            </p>
         }
         {findFieldByKey('social_username') &&
            <p style={{ marginTop: '10px', ...tertiaryStyle }}>
               @{findFieldByKey('social_username')}
            </p>
         }
         <div style={{ marginTop: '10px', ...quaternaryStyle }}>
            {socialPlatformsMerged.map((platform) => (
               <SocialLink
                  key={platform.key}
                  platform={platform.platform}
                  urlPattern={platform.urlPattern}
                  username={platform.username}
                  colorStyle={quaternaryStyle}
                  filter={filter}
               />
            ))}
         </div>
         {confidentialNotice && <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle }}>{confidentialNotice}</p>}
         {poweredBy && <p style={{ marginTop: 'auto', textAlign:'left'}}>
            <a style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
               href={`https://EmailSignatures.app?referrer=email`}
               target={'_blank'}
               rel="noreferrer"
            >
               Powered by EmailSignatures.app
            </a>
         </p>}
      </div>
      </>
   );
};
const renderHorizontalLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px 0',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
            }}
         >
            <img
               src={companyLogo || findFieldByKey('logo_url')}
               alt={findFieldByKey('company_name') + ' Logo'}
               style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px' }}
            />
            <div style={{
               display: 'flex'
            }}>
               <div style={{ flex: '2', textAlign: 'left', paddingLeft: '20px' }}>
                  <div style={{ marginBottom: '10px' }}>
                     <img
                        src={avatar || findFieldByKey('avatar_url')}
                        alt={findFieldByKey('full_name')}
                        style={{
                           borderRadius: '50%',
                           width: '50px',
                           height: '50px',
                           objectFit: 'cover',
                           marginRight: '10px',
                        }}
                     />
                     {findFieldByKey('full_name') && (
                        <strong>{findFieldByKey('full_name')}</strong>
                     )}
                  </div>
                  {findFieldByKey('job_title') && (
                     <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
                  )}
                  {findFieldByKey('company_name') && (
                     <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
                  )}
                  {findFieldByKey('phone_number') && (
                     <p style={tertiaryStyle}>
                        <a
                           href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                           style={{ color: '#999', ...tertiaryStyle }}
                        >
                           {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                        </a>
                     </p>
                  )}
                  {findFieldByKey('email') && (
                     <p style={tertiaryStyle}>
                        <a
                           href={`mailto:${findFieldByKey('email')}`}
                           style={{ color: '#999', ...tertiaryStyle }}
                        >
                           {findFieldByKey('email')}
                        </a>
                     </p>
                  )}
                  {findFieldByKey('social_username') && (
                     <p style={tertiaryStyle}>@{findFieldByKey('social_username')}</p>
                  )}
               </div>
               <div style={{ flex: '1', textAlign: 'right', paddingRight: '20px' }}>
                  <div style={{ marginTop: '10px', ...quaternaryStyle }}>
                     {socialPlatformsMerged.map((platform) => (
                        <SocialLink
                           key={platform.key}
                           platform={platform.platform}
                           urlPattern={platform.urlPattern}
                           username={platform.username}
                           colorStyle={quaternaryStyle}
                           filter={filter}
                        />
                     ))}
                  </div>
                  {confidentialNotice && (
                     <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle }}>
                        {confidentialNotice}
                     </p>
                  )}
                  {poweredBy && (
                     <p style={{ marginTop: 'auto', textAlign: 'left' }}>
                        <a
                           style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                           href={`https://EmailSignatures.app?referrer=email`}
                           target={'_blank'}
                           rel="noreferrer"
                        >
                           Powered by EmailSignatures.app
                        </a>
                     </p>
                  )}
               </div>
            </div>
         </div>
      </>
   );
};
const renderCircularLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   
   return (
      <>
         Best Regards,
         <div style={{
            marginTop: 20,
            fontSize: '14px',
            padding: '20px 0',
            backgroundColor: '#f7f7f7',
            fontFamily: "'Helvetica Neue', Arial, sans-serif",
            borderBottom: '1px solid #ddd',
            color: '#333',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...primaryStyle,
         }}>
            <img
               src={avatar || findFieldByKey('avatar_url')}
               alt={findFieldByKey('full_name')}
               style={{
                  borderRadius: '50%',
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
               }}
            />
            <div style={{ textAlign: 'center', marginTop: 10 }}>
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
               {findFieldByKey('social_username') && (
                  <p style={tertiaryStyle}>@{findFieldByKey('social_username')}</p>
               )}
               <div style={{ marginTop: '10px', ...quaternaryStyle }}>
                  {socialPlatformsMerged.map((platform) => (
                     <SocialLink
                        key={platform.key}
                        platform={platform.platform}
                        urlPattern={platform.urlPattern}
                        username={platform.username}
                        colorStyle={quaternaryStyle}
                        filter={filter}
                     />
                  ))}
               </div>
               {confidentialNotice && (
                  <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle }}>
                     {confidentialNotice}
                  </p>
               )}
               {poweredBy && (
                  <p style={{ marginTop: 'auto', textAlign: 'center' }}>
                     <a
                        style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                        href={`https://EmailSignatures.app?referrer=email`}
                        target={'_blank'}
                        rel="noreferrer"
                     >
                        Powered by EmailSignatures.app
                     </a>
                  </p>
               )}
            </div>
         </div>
      </>
   );
};
const renderSidebarLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   
   return (
      <>
         Best Regards,
         <div style={{
            marginTop: 20,
            fontSize: '14px',
            padding: '20px 0',
            backgroundColor: '#f7f7f7',
            fontFamily: "'Helvetica Neue', Arial, sans-serif",
            borderBottom: '1px solid #ddd',
            color: '#333',
            display: 'flex',
            ...primaryStyle,
         }}>
            <div style={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
               borderRight: '1px solid #ddd',
               paddingRight: 20,
            }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                     marginBottom: 10,
                  }}
               />
               <div style={{ marginTop: '10px', ...quaternaryStyle }}>
                  {socialPlatformsMerged.map((platform) => (
                     <SocialLink
                        key={platform.key}
                        platform={platform.platform}
                        urlPattern={platform.urlPattern}
                        username={platform.username}
                        colorStyle={quaternaryStyle}
                        filter={filter}
                     />
                  ))}
               </div>
            </div>
            <div style={{ paddingLeft: 20 }}>
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
               {findFieldByKey('social_username') && (
                  <p style={tertiaryStyle}>@{findFieldByKey('social_username')}</p>
               )}
               {confidentialNotice && (
                  <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle }}>
                     {confidentialNotice}
                  </p>
               )}
               {poweredBy && (
                  <p style={{ marginTop: 'auto' }}>
                     <a
                        style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                        href={`https://EmailSignatures.app?referrer=email`}
                        target={'_blank'}
                        rel="noreferrer"
                     >
                        Powered by EmailSignatures.app
                     </a>
                  </p>
               )}
            </div>
         </div>
      </>
   );
};
const renderDualSidebarTemplate = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   
   return (
      <>
         Best Regards,
         <div style={{
            marginTop: 20,
            fontSize: '14px',
            padding: '20px 0',
            backgroundColor: '#f7f7f7',
            fontFamily: "'Helvetica Neue', Arial, sans-serif",
            borderBottom: '1px solid #ddd',
            color: '#333',
            display: 'grid',
            gridTemplateColumns: '1fr 2fr 1fr',
            gap: '10px',
            ...primaryStyle,
         }}>
            <div style={{ textAlign: 'center' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                     justifySelf: 'center',
                     alignSelf: 'center',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div>
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            <div>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
               {confidentialNotice && (
                  <p style={{ fontSize: '10px', color: '#999', ...quaternaryStyle, textAlign: 'center' }}>
                     {confidentialNotice}
                  </p>
               )}
               {poweredBy && (
                  <p style={{ fontSize: '10px', color: '#999', textAlign: 'center' }}>
                     <a
                        style={{ color: '#999' }}
                        href={`https://EmailSignatures.app?referrer=email`}
                        target={'_blank'}
                        rel="noreferrer"
                     >
                        Powered by EmailSignatures.app
                     </a>
                  </p>
               )}
            </div>
         </div>
      </>
   );
};

const renderStackedLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div style={{
            marginTop: 20,
            fontSize: '14px',
            padding: '20px 0',
            backgroundColor: '#f7f7f7',
            fontFamily: "'Helvetica Neue', Arial, sans-serif",
            borderBottom: '1px solid #ddd',
            color: '#333',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...primaryStyle,
         }}>
            <img
               src={avatar || findFieldByKey('avatar_url')}
               alt={findFieldByKey('full_name')}
               style={{
                  borderRadius: '50%',
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                  marginBottom: 10,
               }}
            />
            {findFieldByKey('full_name') && (
               <strong>{findFieldByKey('full_name')}</strong>
            )}
            {findFieldByKey('job_title') && (
               <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
            )}
            {findFieldByKey('company_name') && (
               <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
            )}
            {findFieldByKey('phone_number') && (
               <p style={tertiaryStyle}>
                  <a
                     href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                     style={{ color: '#999', ...tertiaryStyle }}
                  >
                     {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                  </a>
               </p>
            )}
            {findFieldByKey('email') && (
               <p style={tertiaryStyle}>
                  <a
                     href={`mailto:${findFieldByKey('email')}`}
                     style={{ color: '#999', ...tertiaryStyle }}
                  >
                     {findFieldByKey('email')}
                  </a>
               </p>
            )}
            {findFieldByKey('social_username') && (
               <p style={tertiaryStyle}>@{findFieldByKey('social_username')}</p>
            )}
            <div style={{ marginTop: '10px', ...quaternaryStyle }}>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
            </div>
            <img
               src={companyLogo || findFieldByKey('logo_url')}
               alt={findFieldByKey('company_name') + ' Logo'}
               style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginTop: 10 }}
            />
            {confidentialNotice && (
               <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ marginTop: 'auto' }}>
                  <a
                     style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
         </div>
      </>
   );
};
const renderBlockLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px 0',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
            }}
         >
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div style={{ marginBottom: '20px' }}>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
            </div>
            <div style={{ textAlign: 'center' }}>
               <img
                  src={companyLogo || findFieldByKey('logo_url')}
                  alt={findFieldByKey('company_name') + ' Logo'}
                  style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginBottom: 10 }}
               />
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            {confidentialNotice && (
               <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ marginTop: 'auto', textAlign: 'center' }}>
                  <a
                     style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
         </div>
      </>
   );
};
const renderBoxedLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
               display: 'grid',
               gridTemplateColumns: '1fr 1fr 1fr',
               gap: '20px',
            }}
         >
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', textAlign: 'center' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', textAlign: 'center' }}>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
            </div>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', textAlign: 'center' }}>
               <img
                  src={companyLogo || findFieldByKey('logo_url')}
                  alt={findFieldByKey('company_name') + ' Logo'}
                  style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginBottom: 10 }}
               />
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            {confidentialNotice && (
               <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle, gridColumn: 'span 3' }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ marginTop: 'auto', textAlign: 'center', gridColumn: 'span 3' }}>
                  <a
                     style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
         </div>
      </>
   );
};

const renderWithFooterLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
               display: 'grid',
               gridTemplateColumns: '1fr 1fr 1fr',
               gap: '20px',
            }}
         >
            <div style={{ textAlign: 'center' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
            </div>
            <div style={{ textAlign: 'center' }}>
               <img
                  src={companyLogo || findFieldByKey('logo_url')}
                  alt={findFieldByKey('company_name') + ' Logo'}
                  style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginBottom: 10 }}
               />
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            <div style={{ gridColumn: 'span 3', border: '1px solid #ddd', padding: '10px', borderRadius: '5px', textAlign: 'center' }}>
               {confidentialNotice && (
                  <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle }}>
                     {confidentialNotice}
                  </p>
               )}
               {poweredBy && (
                  <p style={{ marginTop: 'auto', textAlign: 'center' }}>
                     <a
                        style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                        href={`https://EmailSignatures.app?referrer=email`}
                        target={'_blank'}
                        rel="noreferrer"
                     >
                        Powered by EmailSignatures.app
                     </a>
                  </p>
               )}
            </div>
         </div>
      </>
   );
};
const renderLogoAtTopLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
               display: 'grid',
               gridTemplateColumns: '1fr 1fr 1fr',
               gap: '20px',
            }}
         >
            <div style={{ textAlign: 'center', gridColumn: 'span 3' }}>
               <img
                  src={companyLogo || findFieldByKey('logo_url')}
                  alt={findFieldByKey('company_name') + ' Logo'}
                  style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginBottom: 10 }}
               />
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            <div style={{ textAlign: 'center' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
            </div>
            {confidentialNotice && (
               <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle, gridColumn: 'span 3' }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ marginTop: 'auto', textAlign: 'center', gridColumn: 'span 3' }}>
                  <a
                     style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
         </div>
      </>
   );
};
const renderLogoAtBottomLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
               display: 'grid',
               gridTemplateColumns: '1fr 1fr 1fr',
               gap: '20px',
            }}
         >
            <div style={{ textAlign: 'center' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
            </div>
            {confidentialNotice && (
               <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle, gridColumn: 'span 3' }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ marginTop: 'auto', textAlign: 'center', gridColumn: 'span 3' }}>
                  <a
                     style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
            <div style={{ textAlign: 'center', gridColumn: 'span 3' }}>
               <img
                  src={companyLogo || findFieldByKey('logo_url')}
                  alt={findFieldByKey('company_name') + ' Logo'}
                  style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginBottom: 10 }}
               />
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
         </div>
      </>
   );
};
const renderTwoColumnsLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px 0',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
               display: 'grid',
               gridTemplateColumns: '1fr 1fr',
               gap: '20px',
            }}
         >
            <div>
               <div style={{ textAlign: 'center' }}>
                  <img
                     src={avatar || findFieldByKey('avatar_url')}
                     alt={findFieldByKey('full_name')}
                     style={{
                        borderRadius: '50%',
                        width: '80px',
                        height: '80px',
                        objectFit: 'cover',
                     }}
                  />
                  {findFieldByKey('full_name') && (
                     <strong>{findFieldByKey('full_name')}</strong>
                  )}
                  {findFieldByKey('job_title') && (
                     <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
                  )}
                  {findFieldByKey('company_name') && (
                     <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
                  )}
               </div>
               <div>
                  {socialPlatformsMerged.map((platform) => (
                     <SocialLink
                        key={platform.key}
                        platform={platform.platform}
                        urlPattern={platform.urlPattern}
                        username={platform.username}
                        colorStyle={quaternaryStyle}
                        filter={filter}
                     />
                  ))}
               </div>
            </div>
            <div style={{ textAlign: 'center' }}>
               <img
                  src={companyLogo || findFieldByKey('logo_url')}
                  alt={findFieldByKey('company_name') + ' Logo'}
                  style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginBottom: 10 }}
               />
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            {confidentialNotice && (
               <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle, gridColumn: 'span 2' }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ marginTop: 'auto', textAlign: 'center', gridColumn: 'span 2' }}>
                  <a
                     style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
         </div>
      </>
   );
};
const renderThreeColumnsLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px 0',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
               display: 'grid',
               gridTemplateColumns: '1fr 1fr 1fr',
               gap: '20px',
            }}
         >
            <div style={{ textAlign: 'center' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
            </div>
            <div style={{ textAlign: 'center' }}>
               <img
                  src={companyLogo || findFieldByKey('logo_url')}
                  alt={findFieldByKey('company_name') + ' Logo'}
                  style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginBottom: 10 }}
               />
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            {confidentialNotice && (
               <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle, gridColumn: 'span 3' }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ marginTop: 'auto', textAlign: 'center', gridColumn: 'span 3' }}>
                  <a
                     style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
         </div>
      </>
   );
};
const renderCenteredLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   return (
      <>
         Best Regards,
         <div
            style={{
               marginTop: 20,
               fontSize: '14px',
               padding: '20px 0',
               backgroundColor: '#f7f7f7',
               fontFamily: "'Helvetica Neue', Arial, sans-serif",
               borderBottom: '1px solid #ddd',
               color: '#333',
               ...primaryStyle,
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
            }}
         >
            <div style={{ textAlign: 'center' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
            </div>
            <div style={{ textAlign: 'center' }}>
               <img
                  src={companyLogo || findFieldByKey('logo_url')}
                  alt={findFieldByKey('company_name') + ' Logo'}
                  style={{ maxHeight: '50px', width: 'auto', maxWidth: '300px', marginBottom: 10 }}
               />
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            {confidentialNotice && (
               <p style={{ marginTop: '10px', fontSize: '10px', color: '#999', ...quaternaryStyle }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ marginTop: 'auto', textAlign: 'center' }}>
                  <a
                     style={{ marginTop: '10px', fontSize: '10px', color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
         </div>
      </>
   );
};
const renderMinimalistLayout = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   
   return (
      <>
         Best Regards,
         <div style={{
            marginTop: 20,
            fontSize: '14px',
            padding: '20px 0',
            backgroundColor: '#f7f7f7',
            fontFamily: "'Helvetica Neue', Arial, sans-serif",
            borderBottom: '1px solid #ddd',
            color: '#333',
            display: 'grid',
            gap: '10px',
            ...primaryStyle,
         }}>
            {avatar && (
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                     justifySelf: 'center',
                     alignSelf: 'center',
                  }}
               />
            )}
            <div style={{ textAlign: 'center' }}>
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div style={{ textAlign: 'center' }}>
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
            </div>
            {socialPlatformsMerged.map((platform) => (
               <SocialLink
                  key={platform.key}
                  platform={platform.platform}
                  urlPattern={platform.urlPattern}
                  username={platform.username}
                  colorStyle={quaternaryStyle}
                  filter={filter}
               />
            ))}
            {confidentialNotice && (
               <p style={{ fontSize: '10px', color: '#999', ...quaternaryStyle, gridColumn: 'span 2', textAlign: 'center' }}>
                  {confidentialNotice}
               </p>
            )}
            {poweredBy && (
               <p style={{ fontSize: '10px', color: '#999', gridColumn: 'span 2', textAlign: 'center' }}>
                  <a
                     style={{ color: '#999' }}
                     href={`https://EmailSignatures.app?referrer=email`}
                     target={'_blank'}
                     rel="noreferrer"
                  >
                     Powered by EmailSignatures.app
                  </a>
               </p>
            )}
         </div>
      </>
   );
};
const renderCardViewTemplate = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   
   return (
      <>
         Best Regards,
         <div style={{
            marginTop: 20,
            fontSize: '14px',
            padding: '20px 0',
            backgroundColor: '#f7f7f7',
            fontFamily: "'Helvetica Neue', Arial, sans-serif",
            borderBottom: '1px solid #ddd',
            color: '#333',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '10px',
            ...primaryStyle,
         }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
               <img
                  src={avatar || findFieldByKey('avatar_url')}
                  alt={findFieldByKey('full_name')}
                  style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     objectFit: 'cover',
                     margin: '10px auto 0',
                  }}
               />
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
               <div style={{ marginTop: 'auto' }}>
                  {socialPlatformsMerged.map((platform) => (
                     <SocialLink
                        key={platform.key}
                        platform={platform.platform}
                        urlPattern={platform.urlPattern}
                        username={platform.username}
                        colorStyle={quaternaryStyle}
                        filter={filter}
                     />
                  ))}
               </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
               {findFieldByKey('phone_number') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                     </a>
                  </p>
               )}
               {findFieldByKey('email') && (
                  <p style={tertiaryStyle}>
                     <a
                        href={`mailto:${findFieldByKey('email')}`}
                        style={{ color: '#999', ...tertiaryStyle }}
                     >
                        {findFieldByKey('email')}
                     </a>
                  </p>
               )}
               {confidentialNotice && (
                  <p style={{ fontSize: '10px', color: '#999', ...quaternaryStyle }}>
                     {confidentialNotice}
                  </p>
               )}
               {poweredBy && (
                  <p>
                     <a
                        style={{ fontSize: '10px', color: '#999' }}
                        href={`https://EmailSignatures.app?referrer=email`}
                        target={'_blank'}
                        rel="noreferrer"
                     >
                        Powered by EmailSignatures.app
                     </a>
                  </p>
               )}
            </div>
         </div>
      </>
   );
};
const renderZigzagTemplate = () => {
   const {
      state: {
         confidentialNotice,
         poweredBy,
         primaryStyle,
         secondaryStyle,
         tertiaryStyle,
         quaternaryStyle,
         companyCountryPhone,
         companyLogo,
         avatar,
         socialPlatformsMerged,
         filter
      },
      actions: {
         findFieldByKey
      }
   } = useContext(DemoContext);
   
   return (
      <>
         Best Regards,
         <div style={{
            marginTop: 20,
            fontSize: '14px',
            padding: '20px 0',
            backgroundColor: '#f7f7f7',
            fontFamily: "'Helvetica Neue', Arial, sans-serif",
            borderBottom: '1px solid #ddd',
            color: '#333',
            display: 'grid',
            gridTemplateColumns: '1fr 2fr 1fr',
            gap: '10px',
            ...primaryStyle,
         }}>
            <div style={{ textAlign: 'center' }}>
               {findFieldByKey('full_name') && (
                  <strong>{findFieldByKey('full_name')}</strong>
               )}
               {findFieldByKey('job_title') && (
                  <p style={secondaryStyle}>{findFieldByKey('job_title')}</p>
               )}
               {findFieldByKey('company_name') && (
                  <p style={secondaryStyle}>{findFieldByKey('company_name')}</p>
               )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
               <div style={{ textAlign: 'center' }}>
                  <img
                     src={avatar || findFieldByKey('avatar_url')}
                     alt={findFieldByKey('full_name')}
                     style={{
                        borderRadius: '50%',
                        width: '80px',
                        height: '80px',
                        objectFit: 'cover',
                        margin: '10px auto 0',
                     }}
                  />
               </div>
               <div style={{ textAlign: 'center', marginTop: 10 }}>
                  {findFieldByKey('phone_number') && (
                     <p style={tertiaryStyle}>
                        <a
                           href={`tel:${companyCountryPhone + findFieldByKey('phone_number')}`}
                           style={{ color: '#999', ...tertiaryStyle }}
                        >
                           {prettyPhone(companyCountryPhone + findFieldByKey('phone_number'))}
                        </a>
                     </p>
                  )}
                  {findFieldByKey('email') && (
                     <p style={tertiaryStyle}>
                        <a
                           href={`mailto:${findFieldByKey('email')}`}
                           style={{ color: '#999', ...tertiaryStyle }}
                        >
                           {findFieldByKey('email')}
                        </a>
                     </p>
                  )}
               </div>
            </div>
            <div>
               {socialPlatformsMerged.map((platform) => (
                  <SocialLink
                     key={platform.key}
                     platform={platform.platform}
                     urlPattern={platform.urlPattern}
                     username={platform.username}
                     colorStyle={quaternaryStyle}
                     filter={filter}
                  />
               ))}
               {confidentialNotice && (
                  <p style={{ fontSize: '10px', color: '#999', ...quaternaryStyle, textAlign: 'center' }}>
                     {confidentialNotice}
                  </p>
               )}
               {poweredBy && (
                  <p style={{ fontSize: '10px', color: '#999', textAlign: 'center' }}>
                     <a
                        style={{ color: '#999' }}
                        href={`https://EmailSignatures.app?referrer=email`}
                        target={'_blank'}
                        rel="noreferrer"
                     >
                        Powered by EmailSignatures.app
                     </a>
                  </p>
               )}
            </div>
         </div>
      </>
   );
};





const SocialLink = ({ platform, urlPattern, username, colorStyle, filter }) => {
   return username ? (
      <a
         target={'_blank'}
         href={`${urlPattern(username)}?referrer=https://EmailSignatures.app`}
         style={{ color: '#999', ...colorStyle }}
      >
         <img
            src={`https://s3.amazonaws.com/emailsignatures.app/assets/${platform}.png`}
            alt={platform}
            style={{ height: '30px', width: 'auto', filter }}
         />
      </a>
   ) : null;
};
