export const TEMPLATES = [
  {
    id: '1',
    name: 'Vertical',
    description: 'Traditional layout with information stacked vertically',
    template: {
      structure: 'vertical',
      slots: [
        { slotId: '1', align: 'center', content: 'full_name' },
        { slotId: '2', align: 'center', content: 'job_title' },
        { slotId: '3', align: 'center', content: 'company_name' },
        { slotId: '4', align: 'center', content: 'company_logo' },
        { slotId: '5', align: 'center', content: 'phone_number' },
        { slotId: '6', align: 'center', content: 'email' },
        { slotId: '7', align: 'center', content: 'website' },
        { slotId: '8', align: 'center', content: 'social_links' },
      ],
    },
  },
  {
    id: '2',
    name: 'Horizontal',
    description: 'Modern layout with information stacked horizontally',
    template: {
      structure: 'horizontal',
      slots: [
        { slotId: '1', align: 'left', content: 'company_logo' },
        { slotId: '2', align: 'left', content: 'full_name' },
        { slotId: '3', align: 'left', content: 'job_title' },
        { slotId: '4', align: 'left', content: 'company_name' },
        { slotId: '5', align: 'right', content: 'phone_number' },
        { slotId: '6', align: 'right', content: 'email' },
        { slotId: '7', align: 'right', content: 'social_links' },
        { slotId: '8', align: 'right', content: 'website' },
      ],
    },
  },
  {
    id: '3',
    name: 'Boxed',
    description: 'Each information section is distinctly bordered boxes. Gives a clean and organized look',
    template: {
      structure: 'boxed',
      slots: [
        { slotId: '1', position: 'top-left', content: 'full_name' },
        { slotId: '2', position: 'top-right', content: 'job_title' },
        { slotId: '3', position: 'middle-left', content: 'company_name' },
        { slotId: '4', position: 'middle-right', content: 'phone_number' },
        { slotId: '5', position: 'bottom-left', content: 'email' },
        { slotId: '6', position: 'bottom-right', content: 'website' },
        { slotId: '7', position: 'bottom-left', content: 'company_logo' },
        { slotId: '8', position: 'bottom-right', content: 'social_links' },
      ],
    },
  },
  {
    id: '4',
    name: 'Block',
    description: 'One distinct block, with other information outside. Gives a focused look',
    template: {
      structure: 'blocked',
      slots: [
        { slotId: '1', position: 'top-left', content: 'full_name' },
        { slotId: '2', position: 'top-right', content: 'job_title' },
        { slotId: '3', position: 'middle-left', content: 'company_name' },
        { slotId: '4', position: 'middle-right', content: 'phone_number' },
        { slotId: '5', position: 'bottom-left', content: 'email' },
        { slotId: '6', position: 'bottom-right', content: 'website' },
        { slotId: '7', position: 'bottom-left', content: 'company_logo' },
        { slotId: '8', position: 'bottom-right', content: 'social_links' },
      ],
    },
  },
  {
    id: '5',
    name: 'Sidebar',
    description: 'Information is aligned with a sidebar. Ideal for highlighting important content',
    template: {
      structure: 'sidebar',
      slots: [
        { slotId: '1', position: 'sidebar', content: 'company_logo' },
        { slotId: '2', position: 'main-top', content: 'full_name' },
        { slotId: '3', position: 'main-middle', content: 'job_title' },
        { slotId: '4', position: 'main-bottom', content: 'company_name' },
        { slotId: '5', position: 'sidebar', content: 'phone_number' },
        { slotId: '6', position: 'sidebar', content: 'email' },
        { slotId: '7', position: 'sidebar', content: 'website' },
        { slotId: '8', position: 'sidebar', content: 'social_links' },
      ],
    },
  },
  {
    id: '6',
    name: 'Dual Sidebar',
    description: 'Two sidebars border the main content.',
    template: {
      structure: 'dual-sidebar',
      slots: [
        { slotId: '1', position: 'left-sidebar', content: 'company_logo' },
        { slotId: '2', position: 'main-top', content: 'full_name' },
        { slotId: '3', position: 'main-middle', content: 'job_title' },
        { slotId: '4', position: 'main-bottom', content: 'company_name' },
        { slotId: '5', position: 'right-sidebar', content: 'phone_number' },
        { slotId: '6', position: 'right-sidebar', content: 'email' },
        { slotId: '7', position: 'right-sidebar', content: 'website' },
      ],
    },
  },
  {
    id: '7',
    name: 'Circle',
    description: 'Information is arranged around a central circle. Perfect for showing off a logo or profile picture',
    template: {
      structure: 'circle',
      slots: [
        { slotId: '1', position: 'center', content: 'company_logo' },
        { slotId: '2', position: 'top', content: 'full_name' },
        { slotId: '3', position: 'left', content: 'job_title' },
        { slotId: '4', position: 'right', content: 'company_name' },
        { slotId: '5', position: 'bottom-left', content: 'phone_number' },
        { slotId: '6', position: 'bottom-right', content: 'email' },
        { slotId: '7', position: 'bottom-left', content: 'website' },
        { slotId: '8', position: 'bottom-right', content: 'social_links' },
      ],
    },
  },
  {
    id: '8',
    name: 'Stacked',
    description: 'Information is aligned in a vertical stack. Ideal for highlighting important content',
    template: {
      structure: 'stacked',
      slots: [
        { slotId: '1', position: 'sidebar', content: 'company_logo' },
        { slotId: '2', position: 'main-top', content: 'full_name' },
        { slotId: '3', position: 'main-middle', content: 'job_title' },
        { slotId: '4', position: 'main-bottom', content: 'company_name' },
        { slotId: '5', position: 'sidebar', content: 'phone_number' },
        { slotId: '6', position: 'sidebar', content: 'email' },
        { slotId: '7', position: 'sidebar', content: 'website' },
        { slotId: '8', position: 'sidebar', content: 'social_links' },
      ],
    },
  },
  {
    id: '9',
    name: 'Logo at Top',
    description: 'This layout puts the logo or photo at the top, with the rest of the signature elements arranged below it.',
    template: {
      structure: 'logo-at-top',
      slots: [
        { slotId: '1', position: 'top', content: 'logo' },
        { slotId: '2', position: 'middle', content: 'full_name' },
        { slotId: '3', position: 'middle', content: 'title' },
        { slotId: '4', position: 'middle', content: 'contact' },
      ],
    },
  },
  {
    id: '10',
    name: 'Logo at Bottom',
    description: 'The inverse of the above, with the logo or photo at the bottom.',
    template: {
      structure: 'logo-at-bottom',
      slots: [
        { slotId: '1', position: 'middle', content: 'full_name' },
        { slotId: '2', position: 'middle', content: 'title' },
        { slotId: '3', position: 'middle', content: 'contact' },
        { slotId: '4', position: 'bottom', content: 'logo' },
      ],
    },
  },
  {
    id: '11',
    name: 'With Footer',
    description: 'This layout adds a horizontal bar at the bottom of the signature, which could contain a website link, social icons, or a legal disclaimer.',
    template: {
      structure: 'with-footer',
      slots: [
        { slotId: '1', position: 'top', content: 'full_name' },
        { slotId: '2', position: 'middle', content: 'title' },
        { slotId: '3', position: 'middle', content: 'contact' },
        { slotId: '4', position: 'footer', content: 'extra' },
      ],
    },
  },
  {
    id: '12',
    name: 'Two Columns',
    description: 'Splits the signature into two columns. The left column might include a logo or photo, while the right column contains text elements',
    template: {
      structure: 'two-columns',
      slots: [
        { slotId: '1', column: 'left', content: 'logo' },
        { slotId: '2', column: 'right', content: 'full_name' },
        { slotId: '3', column: 'right', content: 'title' },
        { slotId: '4', column: 'right', content: 'contact' },
      ],
    },
  },
  {
    id: '13',
    name: 'Three Columns',
    description: 'Similar to the two columns layout, but with an additional column. This can be useful for including extra information like social media icons or a QR code.',
    template: {
      structure: 'three-columns',
      slots: [
        { slotId: '1', column: 'left', content: 'logo' },
        { slotId: '2', column: 'middle', content: 'full_name' },
        { slotId: '3', column: 'middle', content: 'title' },
        { slotId: '4', column: 'middle', content: 'contact' },
        { slotId: '5', column: 'right', content: 'extra' },
      ],
    },
  },
  {
    id: '14',
    name: 'Centered',
    description: 'A layout with all elements centered can look very professional and balanced, especially for more minimalist signatures.',
    template: {
      structure: 'centered',
      slots: [
        { slotId: '1', position: 'center', content: 'full_name' },
        { slotId: '2', position: 'center', content: 'title' },
        { slotId: '3', position: 'center', content: 'contact' },
        { slotId: '4', position: 'center', content: 'logo' },
      ],
    },
  },
  {
    id: '15',
    name: 'Minimalist',
    description: 'Simplicity is key. Only include the essential information.',
    template: {
      structure: 'minimalist',
      slots: [
        { slotId: '1', position: 'left', content: 'full_name' },
        { slotId: '2', position: 'left', content: 'job_title' },
        { slotId: '3', position: 'left', content: 'email' },
      ],
    },
  },
  {
    id: '16',
    name: 'Card View',
    description: 'Mimics a business card layout for a professional appearance.',
    template: {
      structure: 'card-view',
      slots: [
        { slotId: '1', position: 'top-left', content: 'full_name' },
        { slotId: '2', position: 'top-right', content: 'job_title' },
        { slotId: '3', position: 'middle-left', content: 'phone_number' },
        { slotId: '4', position: 'middle-right', content: 'email' },
        { slotId: '5', position: 'bottom-left', content: 'website' },
        { slotId: '6', position: 'bottom-right', content: 'company_logo' },
      ],
    },
  },
  {
    id: '17',
    name: 'Zigzag',
    description: 'Dynamic layout with information flowing in a zigzag pattern.',
    template: {
      structure: 'zigzag',
      slots: [
        { slotId: '1', position: 'left', content: 'full_name' },
        { slotId: '2', position: 'right', content: 'job_title' },
        { slotId: '3', position: 'left', content: 'company_name' },
        { slotId: '4', position: 'right', content: 'phone_number' },
        { slotId: '5', position: 'left', content: 'email' },
        { slotId: '6', position: 'right', content: 'website' },
      ],
    },
  }
];
