import SvgIcon from '@mui/material/SvgIcon';

const StackOverflowIcon = (props) => {
   return (
      <SvgIcon {...props}>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512px" height="512px"><path d="M 19.03125 1.96875 L 17.09375 2.5 L 18.90625 9.25 L 20.84375 8.75 Z M 14.09375 4.0625 L 12.5625 5.34375 L 17.0625 10.71875 L 18.59375 9.4375 Z M 10.71875 7.53125 L 9.71875 9.25 L 15.78125 12.75 L 16.78125 11.03125 Z M 8.96875 11.125 L 8.4375 13.03125 L 15.21875 14.84375 L 15.71875 12.9375 Z M 8.1875 14.28125 L 8.03125 16.28125 L 15 16.875 L 15.1875 14.875 Z M 5 15 L 5 22 L 18 22 L 18 15 L 16 15 L 16 20 L 7 20 L 7 15 Z M 8 17 L 8 19 L 15 19 L 15 17 Z"/></svg>
      </SvgIcon>
   );
}
export default StackOverflowIcon;
