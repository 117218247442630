import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../src/assets/fonts/remixicon.css';
import '../src/assets/css/style.css';

// Components
/*
import Home from './component/Pages/Home/Home';
import HomeTwo from './component/Pages/HomeTwo/HomeTwo';
import HomeThree from './component/Pages/HomeThree/HomeThree';
import HomeFour from './component/Pages/HomeFour/HomeFour';
import HomeFive from './component/Pages/HomeFive/HomeFive';
import HomeSix from './component/Pages/HomeSix/HomeSix';
import About from './component/Pages/About/About';
import Contact from './component/Pages/Contact/Contact';
import Blog from './component/Pages/Blog/Blog';
import BlogDetails from './component/Pages/Blog/BlogDetails';
import Services from './component/Pages/Services/Services';
import Team from './component/Pages/Team/Team';
import TeamDetails from './component/Pages/TeamDetails/TeamDetails';
*/
import Price from './component/Pages/Price/Price';
import Faq from './component/Pages/Faq/Faq';
import Login from './component/Pages/Login/Login';
import SignUp from './component/Pages/SignUp/SignUp';
import Error404 from './component/Pages/Error404/Error404';
import ScrollUpBtn from './component/Shared/ScrollUpBtn';
import APIDocs from "./component/Pages/APIDocs/APIDocs";
import HomeInUse from "./component/Pages/HomeInUse/Home";
import Thanks from "./component/Pages/Thanks/Thanks";
import Privacy from "./component/Pages/Privacy/Privacy";
import Terms from "./component/Pages/Terms/Terms";
import HomeFour from "./component/Pages/HomeFour/HomeFour";
import {LoginScreen} from "./component/Pages/LoginScreen";
import {
   OrganizationContextProvider,
   UserContextProvider,
   ThemeContext,
   OrganizationContext,
   UserContext
} from "./lib/contexts";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import {SnackbarProvider} from "notistack";
import {QueryClient, QueryClientProvider} from "react-query";
import {LightTheme} from "./lib/themes";
import {useContext} from "react";
import Dashboard from "./component/App/Dashboard/Dashboard";
import Customize from "./component/App/Customize/Customize";
import FAQManagementScreen from "./component/App/FAQManagementScreen/FAQManagementScreen";
import {DemoContextProvider} from "./lib/contexts/DemoContext";
import ContactManagementScreen from "./component/App/ContactManagementScreen/ContactManagementScreen";
import PriceStripeTable from "./component/Pages/Price/PriceStripeTable";
import useCurrentUser from "./lib/hooks/useCurrentUser";
import Blog from "./component/Pages/Blog/Blog";
import BlogDetails from "./component/Pages/Blog/BlogDetails";
import PromptBrowser from "./component/App/PromptBrowser";

function MarketingApp() {
  return (
     <DemoContextProvider>
    <div>
      <Helmet>
        <title>EmailSignature.app - Make Every Email Count</title>
        <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <Routes>
                         <Route path='/' element={<HomeFour />}></Route>
                         <Route path='/faq' element={<Faq />}></Route>
                         <Route path='/price' element={<Price />}></Route>
                         <Route path='/pricing' element={<Price />}></Route>
                         <Route path='/pricing/free' element={<PriceStripeTable />}></Route>
                         <Route path='/thanks' element={<Thanks />}></Route>
                         <Route path='/privacy' element={<Privacy />}></Route>
                         <Route path='/terms' element={<Terms />}></Route>
                         <Route path='/login' element={<LoginScreen />}></Route>
         
            <Route path='/dashboard' element={<LoginScreen />}></Route>
            <Route path='/dashboard/signatures/' element={<LoginScreen />}></Route>
            <Route path='/dashboard/signatures/:signatureId' element={<LoginScreen />}></Route>
            <Route path='/dashboard/create' element={<LoginScreen />}></Route>
            <Route path='/dashboard/contacts/' element={<LoginScreen />}></Route>
            <Route path='/dashboard/contacts/:contactId' element={<LoginScreen />}></Route>
   
         <Route path='/blog' element={<Blog />}></Route>
         <Route path='/blog/:slug' element={<BlogDetails />}></Route>
                         {/*
        <Route path='/home2' element={<HomeTwo />}></Route>
        <Route path='/home3' element={<HomeThree />}></Route>
        <Route path='/home4' element={<HomeFour />}></Route>
        <Route path='/home5' element={<HomeFive />}></Route>
        <Route path='/home6' element={<HomeSix />}></Route>
        <Route path='/about' element={<About />} ></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/blog' element={<Blog />}></Route>
        <Route path='/blog-details' element={<BlogDetails />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/price' element={<Price />}></Route>
        <Route path='/team' element={<Team />}></Route>
        <Route path='/team-details' element={<TeamDetails />}></Route>
        <Route path='/faq' element={<Faq />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/signup' element={<SignUp />}></Route>
         */}
                         <Route path='*' element={<Error404 />}></Route>
                      </Routes>
      {/*<ScrollUpBtn />*/}
    </div>
     </DemoContextProvider>
  );
}
function App() {
   const { userID } = useCurrentUser();
  return (
     <DemoContextProvider>
    <div>
      <Helmet>
        <title>EmailSignature.app - Make Every Email Count</title>
        <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <Routes>
         <Route path='*' element={<Customize />}></Route>
   
 {/*        <Route path='/' element={userID ? <Dashboard/> : <Dashboard/>}></Route>
         <Route path='' element={userID ? <Dashboard/> : <Dashboard/>}></Route>
         <Route path='/dashboard' element={<Dashboard/>}></Route>
         <Route path='/signatures/' element={userID ? <FAQManagementScreen /> : <LoginScreen />}></Route>
         <Route path='/signatures/:signatureId' element={userID ? <FAQManagementScreen /> : <LoginScreen />}></Route>
         <Route path='/pricing' element={userID ? <Dashboard/> : <PriceStripeTable />}></Route>
   
         <Route path='/create' element={<Customize />}></Route>
         <Route path='/privacy' element={<Privacy />}></Route>
         <Route path='/terms' element={<Terms />}></Route>
         <Route path='/login' element={userID ? <Dashboard/> : <LoginScreen />}></Route>
   
         <Route path='/blog' element={<Blog />}></Route>
         <Route path='/blog/:slug' element={<BlogDetails />}></Route>
         <Route path='*' element={<Error404 />}></Route>*/}
      </Routes>
      <ToastContainer />
    </div>
     </DemoContextProvider>
  );
}
function AppProvider() {
   const { userID } = useCurrentUser();
   //const { state: {user_id} } = useContext(UserContext);
  //return (userID ? <App/> : <MarketingApp/>);
  return (userID ? <App/> : <App/>);
}

const ThemeContainer = () => {
   const {
      state: { primary_color, secondary_color },
   } = useContext(OrganizationContext);
   return (
      <ThemeContext.Provider value={{
         darkMode: undefined,
         toggleTheme: () => {}
      }}>
         <StyledEngineProvider injectFirst>
            <ThemeProvider theme={LightTheme(primary_color, secondary_color)}>
                  <UserContextProvider>
                        <AppProvider/>
                  </UserContextProvider>
            </ThemeProvider>
         </StyledEngineProvider>
      </ThemeContext.Provider>
   );
};
const AppContainer = () => {
   const queryClient = new QueryClient();
   return (
      <QueryClientProvider client={queryClient}>
         <SnackbarProvider
         anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
         variant={'info'}
         autoHideDuration={4000}
         preventDuplicate={true}
         maxSnack={6}
      >
         <OrganizationContextProvider>
            <ThemeContainer/>
         </OrganizationContextProvider>
         </SnackbarProvider>
      </QueryClientProvider>
   );
};

export default AppContainer;
