import React, {useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import {UserContext} from "../../lib/contexts";
import {DemoContext} from "../../lib/contexts/DemoContext";
import {useMediaQuery, useTheme} from "@mui/material";

const MenuItems = (props) => {
    const { parentMenu } = props;
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
   const matchMD = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
   const {
      state: {
         user_id
      }
   } = useContext(UserContext);

    return (
        <>
           <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
              <Link className={(location.pathname === '/' || location.pathname === '/dashboard') ? 'active' : ''}  to="../../">Dashboard</Link>
           </li>
           <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
              <Link className={location.pathname === '/create' ? 'active' : ''} to="../../create">Create Signature</Link>
           </li>
           <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
              <Link className={location.pathname.startsWith('/signatures') ? 'active' : ''} to="../../signatures">Manage Signatures</Link>
           </li>
           {!user_id && <>
              <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={location.pathname === '/pricing' ? 'active' : ''} to="../../pricing#">Pricing</Link>
              </li>
              <li className={`d-none canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={location.pathname === '/faq' ? 'active' : ''} to="../../faq#">FAQ</Link>
              </li>
           </>}
        </>
    );
}

export default MenuItems;
