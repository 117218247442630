import SvgIcon from '@mui/material/SvgIcon';

const DiscordIcon = (props) => {
   return (
      <SvgIcon {...props}>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512px" height="512px"><path d="M19.229,6.012c-0.903-0.73-2.015-1.246-2.872-1.572c-0.307-0.117-0.653-0.076-0.923,0.111C15.162,4.737,15,5.045,15,5.374 C15,5.72,14.72,6,14.374,6c-1.573,0-3.176,0-4.749,0C9.28,6,9,5.72,9,5.375c0-0.329-0.162-0.638-0.433-0.824 C8.296,4.364,7.95,4.323,7.643,4.441c-0.86,0.329-1.978,0.85-2.894,1.59C3.831,6.882,2,11.861,2,16.165 c0,0.076,0.019,0.15,0.057,0.216c1.265,2.233,4.714,2.817,5.499,2.842c0.005,0.001,0.009,0.001,0.014,0.001 c0.139,0,0.286-0.056,0.351-0.18l0.783-1.485c-0.646-0.164-1.313-0.359-2.04-0.617c-0.521-0.185-0.792-0.757-0.607-1.277 s0.758-0.791,1.277-0.607c3.526,1.254,5.624,1.253,9.345-0.005c0.525-0.175,1.092,0.104,1.268,0.627 c0.177,0.523-0.104,1.091-0.627,1.268c-0.728,0.246-1.392,0.434-2.035,0.594l0.793,1.503c0.065,0.124,0.213,0.18,0.351,0.18 c0.005,0,0.009,0,0.014-0.001c0.786-0.025,4.235-0.61,5.499-2.843C21.981,16.315,22,16.241,22,16.164 C22,11.861,20.169,6.882,19.229,6.012z M9.04,13.988c-0.829,0-1.5-0.893-1.5-1.996c0-1.102,0.671-1.996,1.5-1.996 c0.832-0.11,1.482,0.893,1.5,1.996C10.54,13.095,9.869,13.988,9.04,13.988z M14.996,14.012c-0.829,0-1.5-0.895-1.5-2s0.671-2,1.5-2 s1.5,0.895,1.5,2S15.825,14.012,14.996,14.012z"/></svg>
      </SvgIcon>
   );
}
export default DiscordIcon;
