import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
   Grid,
   Typography,
   Box,
   TextField,
   Paper,
   InputAdornment,
   IconButton, Tooltip, FormHelperText, CircularProgress
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SidebarManagement from "./SidebarManagement";
import {FileCopy} from "@mui/icons-material";
import {DemoContext} from "../../lib/contexts/DemoContext";
import { SOMETHING_WRONG_TRY_AGAIN_LABEL} from "../../lib/constants/constants";
import {Preview} from "../../component/App/Customize/Preview";
const useStyles = makeStyles(theme => ({
  root: {
    display:'block',
    width: '100%',
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
     marginBottom:10
  },
  screen:{
    width:'100%',
  //  maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
//    maxWidth:'80vw',
    margin:'auto auto 40px',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
  containerTop:{
    maxHeight: '78vh',
    overflowY:"auto",
  },
   embedCode: {
      '& textarea':{
         fontSize:'11px !important',
      },
  }
}));

const EmbeddableChatManagement = () => {
  const classes = useStyles();
  const {
    actions: { enqueueSnackbar},
    state: {
       userID,
       embedCode,
      matchMD,
       isLoadingCurrentUser
    },
  } = useContext(DemoContext);
  
   const copyTextToClipboard = useCallback(async () => {
      if ('clipboard' in navigator) {
         return await navigator.clipboard.writeText(embedCode);
      } else {
         return document.execCommand('copy', true, embedCode);
      }
   },[embedCode]);
   const InternalTooltip = React.forwardRef((props, ref) => (
      <Tooltip {...props} ref={ref} />
   ));
   
   const handleCopy = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard()
         .then(() => {
            enqueueSnackbar('Copied embed code.', {variant:'info'})
         })
         .catch((err) => {
            enqueueSnackbar('Your browser settings have prevented automatic copying. Please copy manually.', {variant:'error'})
            //console.log(err);
         });
   }
  return (
     <Box>
       <Grid
          container
          alignItems="center"
          spacing={0}
          className={classes.screen}
       >
         <Grid
            container
            item xs={12}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            className={classes.container}
         >
           <Grid item xs={matchMD ? 12 : 6}>
              {!isLoadingCurrentUser ? <SidebarManagement/> : <div style={{margin:'200px auto auto', display:"block"}}><CircularProgress size={'small'}/></div>}
           </Grid>
           <Grid item xs={matchMD ? 12 : 6}>
             <Paper className={classes.root}>
               <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 20 }}>
                 Preview
               </Typography>
                <FormHelperText>
                   Final display, will be adjusted to fit the device.
                </FormHelperText>
               <Preview />
               
             </Paper>
              {userID && <Paper className={classes.root}>
                 <Typography variant={'subtitle1'} style={{marginTop: 20, marginBottom: 20}}>
                    Embed Code
                 </Typography>
                 <InternalTooltip
                    title={'Please ensure the widget is placed on the same Company Website you\'ve specified.'}>
                    <FormHelperText>
                       To complete installation, Copy and Paste the following snippet directly into your Email Client's
                       settings.
                    </FormHelperText>
                 </InternalTooltip>
                 <TextField
                    rows={11}
                    multiline={true}
                    key='embed_script'
                    id='embed_script'
                    label=''
                    value={embedCode}
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    type={'text'}
                    className={classes.embedCode}
         
                    InputProps={{
                       endAdornment: (
                          <InputAdornment position="end">
                             <InternalTooltip title={'Copy Embed Code'}>
                                <IconButton aria-label="Copy Embed Code" onClick={handleCopy} size="large">
                                   <FileCopy/>
                                </IconButton>
                             </InternalTooltip>
                          </InputAdornment>
                       ),
                    }}
                 />
              </Paper>}
             
             
           </Grid>
         </Grid>
       </Grid>
     </Box>
  );
};
export default EmbeddableChatManagement;
